import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Img from 'gatsby-image'

const people = [
    { name: 'Dyala', quip: 'Design + Biz',  img: 'dyala' },
    { name: 'Zach',  quip: 'Tech Lad',      img: 'zach'  },
    { name: 'Holly', quip: 'Fart Director', img: 'holly' }
]

const description = 'About info for the Echodog devs of the indie PC game Signs of the Sojourner.'

export default ({ data, location }) => (
    <Layout title="About" description={description} location={location} parentClass="content">
        <h1 className="has-text-centered">
            Meet the Team
        </h1>
        <p style={{ paddingBottom: 10 }}>
            Echodog Games is a small indie team based in Los Angeles, California. Our debut game, Signs of the Sojourner, is out now for Windows and Mac! We're continuing to work on supporting it and bringing it to new platforms. If you’d like to join us on our development journey, follow our <a href="https://twitter.com/EchodogGames" target="_blank" rel="nofollow noopener noreferrer">Twitter</a> and subscribe to our <Link to="/#subscribe" state={{ focusSubscribe: true }}>mailing list</Link>!
        </p>
        <p>
            For press inquiries, please see our <a href="/presskit" target="_blank" rel="noopener noreferrer">presskit</a>.
        </p>
        <div className="columns">
            {people.map(({ name, quip, img }) =>
                <div className="column has-text-centered" key={name}>
                    <div className="box">
                        <Img fluid={data[img].childImageSharp.fluid}/>
                        <h3>{ name }</h3>
                        <p>{ quip }</p>
                    </div>
                </div>
            )}
        </div>
    </Layout>
)

export const fluidImageNoFade = graphql`
    fragment fluidImageNoFade on File {
        childImageSharp {
            fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            }
        }
    }
`
export const pageQuery = graphql`
    query {
        dyala: file(relativePath: { eq: "dyala.png" }) {
            ...fluidImageNoFade
        },
        zach: file(relativePath: { eq: "zach.png" }) {
            ...fluidImageNoFade
        },
        holly: file(relativePath: { eq: "holly.png" }) {
            ...fluidImageNoFade
        }
    }
`
